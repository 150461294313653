<div mat-dialog-title>
  <h2 class="d-inline-block">
    {{ 'application_tags.add_user_groups' | translate }}
  </h2>

  <button mat-button mat-dialog-close class="close" type="button">
    <img src="/assets/img/icons/delete-cross-black.svg" alt="Close dialog">
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <p class="mb-3">{{ 'application_tags.modal_desc' | translate }}</p>

  <app-small-tip *ngIf="disabledForUserRole$ | async">{{ 'application_tags.user_not_allowed' | translate }}</app-small-tip>

  <app-validation-message class="d-block mb-4" [type]="ValidationMessageType.Info" *ngIf="editFor === ApplicationTagsModalEditFor.Component">
    {{ 'application_tags.one_system_tag_validation' | translate }}
  </app-validation-message>

  <div class="mb-3">
    <span class="title-blue">{{ 'application_tags.user_group_tags' | translate }}</span>
  </div>

  <div *ngIf="errorMessage$ | async" class="alert alert-danger" role="alert">
    {{ errorMessage$ | async }}
  </div>

  <div *ngIf="selectedSystemTags.length > 1" class="alert alert-warning" role="alert">
    {{ 'application_tags.cant_add_system_tag' | translate }}
  </div>
  <div class="mb-3">
    <app-access-group-control [isComponentControl]="editFor !== ApplicationTagsModalEditFor.ChildItem" [formControl]="userGroupControl" [component]="component" [event]="event" [hideRemove]="disabledForUserRole$ | async" [readonly]="disabledForUserRole$ | async">
    </app-access-group-control>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="start">
  <button mat-button mat-dialog-close type="button" class="btn btn-light pl-4 pr-4 mr-4" [disabled]="isSaving">{{ 'buttons.cancel' | translate }}</button>
  <button mat-button *ngIf="!isSaving" type="button" class="btn btn-primary pl-4 pr-4" (click)="onTagSelectSave($event)" [disabled]="selectedSystemTags.length > 1">{{ 'buttons.save_changes' | translate }}</button>
  <button mat-button *ngIf="isSaving" type="button" class="btn btn-primary pl-4 pr-4"><app-spinner-progress></app-spinner-progress></button>
</mat-dialog-actions>