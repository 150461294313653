import { TokenModel } from "./token.model";

export interface ApiTokenModel {
  jwt_token: string;
  refresh_token: string;
  expiration_date: string;
  system_consents: boolean;
}

export function inflateTokenModel(model: ApiTokenModel): TokenModel {
  return {
    token: model.jwt_token,
    refreshToken: model.refresh_token,
    expireOn: model.expiration_date,
    systemConsents: model.system_consents,
  };
}

export function deflateTokenModel(model: TokenModel): ApiTokenModel {
  return {
    jwt_token: model.token,
    refresh_token: model.refreshToken,
    expiration_date: model.expireOn,
    system_consents: model.systemConsents,
  };
}
