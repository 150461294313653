import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ConsentCheckboxFormModel } from '@store/features/consents/utils/consent.helper';
import { ModelForm } from '@utils/model-form.util';

@Component({
  selector: 'app-consents',
  templateUrl: './consents.component.html',
  styleUrls: ['./consents.component.scss']
})
export class ConsentsComponent {
  @Input() forms: FormArray<FormGroup<ModelForm<ConsentCheckboxFormModel>>>;
  @Input() submitted: boolean;
}
