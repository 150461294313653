import { ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface TabSwitchControlOption {
  label: string;
  value: string | number;
}

type ValueType = string | number;

@Component({
  selector: 'app-tab-switch-control',
  templateUrl: './tab-switch-control.component.html',
  styleUrls: ['./tab-switch-control.component.scss'],
    providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TabSwitchControlComponent),
      multi: true,
    },
  ],
})
export class TabSwitchControlComponent implements ControlValueAccessor  {

  @Input() options: TabSwitchControlOption[] = [];
  @Input() useI18n: boolean = false;
  @Input() disabled: boolean = false;

  value: ValueType;

  onChange = (value: ValueType) => {};
  onTouched = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  onOptionChange(event: MouseEvent, option: TabSwitchControlOption): void {
    event.preventDefault();
    if (this.disabled) {
      return;
    }
  
    this.value = option.value;
    this.onChange(this.value);
    this.onTouched();
  }

  writeValue(option: ValueType): void {
    if (option !== undefined && option !== null) {
      this.value = option;
      this.cdr.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  trackByFn(index: number, option: TabSwitchControlOption): string | number {
    return option.value;
  }

}
