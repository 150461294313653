import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppStoreModule } from './app-store.module';

import { SharedModule } from '@shared/shared.module';
import { CoreModule } from './core/core.module';

import { FullApiService } from '@shared/providers/full-api.service';
import { GoogleApiService } from '@shared/providers/google-api.service';

import { STRIPE_API_KEY } from '@shared/providers/stripe.service';

import { CoalescingComponentFactoryResolver } from '@shared/providers/coalescing-component-factory-resolver.service';

import { AuthGuard } from '@shared/guards/auth.guard';
import { NoAuthGuard } from '@shared/guards/no-auth.guard';

import { AppComponent } from '@app/app.component';

import * as env from '@env/environment';
import { AppIdInterceptor } from '@app/core/interceptors/app-id.interceptor';
import { FacebookModule } from 'ngx-facebook';
import { externalRedirectHandlerFactory } from '@utils/external-redirect-handler.util';
import { Router } from '@angular/router';
import { RoleGuard } from '@shared/guards/role.guard';
import { DeviceIdInterceptor } from './core/interceptors/device-id.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { HeaderLangInterceptor } from './core/interceptors/header-lang.interceptor';
import { ParamsRequiredGuard } from '@shared/guards/params-required.guard';
import { ForbiddenInterceptor } from './core/interceptors/forbidden.interceptor';
import { ConsentStoreModule } from '@store/features/consents/consents-store.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    AppRoutingModule,
    AppStoreModule,
    ConsentStoreModule,
    SharedModule,
    CoreModule,
    FacebookModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: externalRedirectHandlerFactory,
      deps: [Router],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: DeviceIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderLangInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: STRIPE_API_KEY, useValue: env.STRIPE_KEY },
    CoalescingComponentFactoryResolver,
    AuthGuard,
    NoAuthGuard,
    RoleGuard,
    ParamsRequiredGuard,
    FullApiService,
    GoogleApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(coalescingResolver: CoalescingComponentFactoryResolver) {
    coalescingResolver.init();
  }
}
