import { HttpErrorResponse } from '@angular/common/http';

export function extractErrorFrom(err: Error);
export function extractErrorFrom(err: HttpErrorResponse) {
  // err.errors => ten przypadek
  if (err && err.error && err.error.errors) {
    return err.error.errors.map(msg => msg.code).join('\n');
  }

  if (err && err.message) {
    return err.message;
  }

  if (err && err instanceof Error) {
    return (err as Error).stack;
  }

  if (!err) {
    return 'Unknown Internal Error (500)';
  }

  if (err && err.error && err.error.error && err.error.error.errors && err.error.error.errors[0]) {
    return err.error.error.errors[0].message;
  }

  return JSON.stringify(err);
}

export function extractError(err: Error, prefix?: string);
export function extractError(err: HttpErrorResponse, prefix?: string) {
  if (err.status === 500) {
    return 'error.default';
  }
  const errorCodeMainPart = extractErrorFrom(err);
  if (!errorCodeMainPart) {
    return 'error.default';
  }
  if (prefix) {
    return `error.${prefix}_${errorCodeMainPart}`;
  }
  return 'error.default';
}