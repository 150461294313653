import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true,
    },
  ],
})
export class ChipsInputComponent implements ControlValueAccessor {

  @Input() placeholder: string = '';
  @Input() highlighted: string[] = [];

  tags: string[] = [];
  newTag: string = '';

  onChange = (tags: string[]) => {};
  onTouched = () => {};


  isTagHighlighted(tag: string): boolean {
    return this.highlighted.includes(tag);
  }

  addTag(): void {
    if (this.newTag && !this.tags.includes(this.newTag)) {
      this.tags = [...this.tags, this.newTag];
      this.newTag = '';
      this.onChange(this.tags);
    }
  }

  removeTag(tag: string): void {
    this.tags = this.tags.filter(t => t !== tag);
    this.onChange(this.tags);
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.addTag();
      event.preventDefault();
    }
  }

  writeValue(tags: string[]): void {
    if (tags) {
      this.tags = tags;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
