import { ConsentDisplayConfigApiModel, ConsentDisplayExpandableApiModel, ConsentDisplayPlaceholderApiModel } from "../api/consent-display.api-model";
import { ConsentDisplayConfigModel, ConsentDisplayExpandableModel, ConsentDisplayPlaceholderModel } from "../consent-display.model";

export function mapConsentDisplayConfigModel(model: ConsentDisplayConfigApiModel): ConsentDisplayConfigModel {
  return {
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderModel),
    expandable: model.expandable ? mapConsentDisplayExpandableModel(model.expandable) : null,
  }
}

export function mapConsentDisplayPlaceholderModel(model: ConsentDisplayPlaceholderApiModel): ConsentDisplayPlaceholderModel {
  return {
    value: model.value,
    label: model.label,
  }
}

export function mapConsentDisplayExpandableModel(model: ConsentDisplayExpandableApiModel): ConsentDisplayExpandableModel {
  return {
    button: model.button,
    text: model.text,
    placeholders: model.placeholders.map(mapConsentDisplayPlaceholderModel),
  }
}