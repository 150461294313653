import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { generateLinkTokenRandomString } from '@components/product/_helpers/product-ticket.helpers';

@Component({
  selector: 'app-input-value-generator',
  templateUrl: './input-value-generator.component.html',
  styleUrls: ['./input-value-generator.component.scss'],
})
export class InputValueGeneratorComponent implements ControlValueAccessor {

  @Input() placeholder: string;
  @Input() whitespaces: boolean = false;
  @Input() disabled = false;

  _value: string = '';

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.propagateChange(this._value);
  }

  get status(): string {
    return this.ngControl.control ? this.ngControl.control.status : 'invalid';
  }

  get touched(): boolean {
    return this.ngControl.control ? this.ngControl.control.touched : false;
  }

  private onChange: (value: string) => {};
  private onTouched: () => {};

  constructor(
    @Optional() @Self() private ngControl: NgControl,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  onKeydown(event: KeyboardEvent): void {
    if (!this.whitespaces && event.key === ' ') {
      event.preventDefault();
    }
  }

  public generateValue(): void {
    this.value = generateLinkTokenRandomString();
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  onInput(event: Event): void {
    let input = (event.target as HTMLInputElement).value;
    if (!this.whitespaces) {
      input = input.replace(/\s/g, '');
    }
    this.value = input;
  }

  propagateChange = (_: any) => { };
  propagateTouched = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  setTouched($event: any) {
    this.propagateTouched($event);
  }

}
