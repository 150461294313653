import { Subscription } from 'rxjs';
import { Component, Input } from '@angular/core';
import { EventModel } from '@store/features/event/models/event.model';
import { getEventLandingUrl } from '@utils/landing.util';

@Component({
  selector: 'app-live-preview',
  templateUrl: './live-preview.component.html',
  styleUrls: ['./live-preview.component.scss']
})
export class LivePreviewComponent {
  @Input() event: EventModel;
  @Input() mobilePreview: boolean = false;

  routeSub$?: Subscription;

  constructor() {}

  get landingPageUrl(): string {
    const slug = this.event && this.event.lpUrl;
    return getEventLandingUrl(slug);
  }

  get qrcode(): string {
    return this.event && this.event.qrcode;
  }
}