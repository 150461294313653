import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from '@app/backend/services/profile.service';
import { ConsentUserAcceptModel } from '@store/features/consents/models/consent-user-accept.model';
import { ConsentModel } from '@store/features/consents/models/consent.model';
import { ConsentProviderService } from '@store/features/consents/providers/consent-provider.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ValidationMessageType } from '../validation-message/validation-message.component';
import { TokenService } from '@app/core/services/token.service';
import { ConsentCheckboxFormModel, ConsentHelper } from '@store/features/consents/utils/consent.helper';
import { ModelForm } from '@utils/model-form.util';

@Component({
  selector: 'app-consents-dialog',
  templateUrl: './consents-dialog.component.html',
  styleUrls: ['./consents-dialog.component.scss']
})
export class ConsentsDialogComponent implements OnInit, OnDestroy {
  public ValidationMessageType = ValidationMessageType;

  loaded$: Observable<boolean>;
  consents$: Observable<ConsentModel[]>;
  error$: Observable<Error | null>;

  consents: ConsentModel[];

  forms: FormArray<FormGroup<ModelForm<ConsentCheckboxFormModel>>>;

  submitted = false;

  private subs = new Subscription();

  constructor(
    private consentProvider: ConsentProviderService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<ConsentsDialogComponent>,
    private profileService: ProfileService,
    private tokenService: TokenService,
  ) {
    this.consents$ = this.consentProvider.getEntities();
    this.loaded$ = this.consentProvider.getLoaded();
    this.error$ = this.consentProvider.getError();
  }

  ngOnInit(): void {
    this.populate();
    this.setupListeners();
    this.forms = new FormArray([]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  handleStateChanged(state: boolean, i: number): void {
    const form = this.forms.at(i) as FormGroup;
    form.controls.checked.setValue(state);
  }

  changeFormExpanded(event: MouseEvent, i: number): void {
    event.preventDefault();
    const form = this.forms.at(i) as FormGroup;
    form.controls.expanded.setValue(!form.controls.expanded.value);
  }

  save(): void {
    console.log(this.forms);
    this.submitted = true;
    if (this.forms.invalid) {
      return;
    }
    const payload: ConsentUserAcceptModel[] = [];
    this.forms.controls.forEach((form: FormGroup) => {
      const data = form.value.data as ConsentModel;
      payload.push({
        consentSchemaId: data.id,
        versionNumber: data.versionNumber,
        accepted: form.value.checked,
      });
    });
    this.consentProvider.accept(payload);
  }

  logout(): void {
    this.profileService.signOut();
    this.dialogRef.close();
  }

  private createCheckboxForm(id: number, consent: ConsentModel) {
    this.forms.push(ConsentHelper.createCheckboxForm(this.sanitizer, id, consent));
  }

  private populate(): void {
    this.forms = new FormArray([]);
    this.subs.add(combineLatest([
      this.loaded$, this.consents$,
    ]).pipe(filter(([loaded, consents]) => !!loaded))
      .subscribe(([_, consents]) => {
        this.consents = consents;
        consents.forEach((consent, index) => this.createCheckboxForm(index, consent));
      }));
  }

  private setupListeners(): void {
    this.subs.add(this.consentProvider.onAcceptSuccess().pipe(first())
      .subscribe(() => {
        this.subs.add(this.tokenService.onTokenRefreshFailure().pipe(first()).subscribe(
          () => {
            this.logout();
            this.dialogRef.close();
          }
        ));

        this.subs.add(this.tokenService.onTokenRefreshSuccess().pipe(first()).subscribe(
          () => {
            window.location.reload();
          }
        ));
      }));
  }

}