import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { acceptUserConsents, acceptUserConsentsFailure, acceptUserConsentsSuccess, loadUserAppConsents } from "../actions/consent.actions";
import { Observable } from "rxjs";
import { selectConsentAccepting, selectConsentEntities, selectConsentError, selectConsentLoaded, selectConsentLoading } from "..";
import { ConsentModel } from "../models/consent.model";
import { ConsentUserAcceptModel } from "../models/consent-user-accept.model";
import { Actions, ofType } from "@ngrx/effects";

@Injectable({
  providedIn: 'root'
})
export class ConsentProviderService {
  constructor(
    private store$: Store<any>,
    private actions$: Actions,
  ) {}

  loadUserAppConsents(): void {
    this.store$.dispatch(loadUserAppConsents());
  }

  accept(payload: ConsentUserAcceptModel[]): void {
    this.store$.dispatch(acceptUserConsents({ payload }));
  }

  // selectors

  getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectConsentLoading));
  }

  getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectConsentLoaded));
  }

  getAccepting(): Observable<boolean> {
    return this.store$.pipe(select(selectConsentAccepting));
  }
  
  getError(): Observable<Error | null> {
    return this.store$.pipe(select(selectConsentError));
  }

  getEntities(): Observable<ConsentModel[]> {
    return this.store$.pipe(select(selectConsentEntities));
  }

  // actions
  onAcceptSuccess(): Observable<void> {
    return this.actions$.pipe(
      ofType(acceptUserConsentsSuccess)
    );
  }

  onAcceptFailure(): Observable<void> {
    return this.actions$.pipe(
      ofType(acceptUserConsentsFailure)
    );
  }

}