import { TagDatabaseComponent } from '@components/tag-database/tag-database/tag-database.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class TagDatabaseModalService {
  constructor(private dialog: MatDialog) {}

  dialogRef: MatDialogRef<TagDatabaseComponent>;

  openEditDialog(): MatDialogRef<TagDatabaseComponent> {
    return this.openEditModal();
  }

  private openEditModal(): MatDialogRef<TagDatabaseComponent> {
    this.dialogRef = this.dialog.open(TagDatabaseComponent, {
      width: '50%', height: '100%', position: { right: '0' }, disableClose: true, panelClass: ['animated-modal']
    });
    this.dialogRef.componentInstance.isModal = true;
    return this.dialogRef;
  }
}
