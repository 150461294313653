<h2 mat-dialog-title>{{ 'consents.dialog_title' | translate }}</h2>

<mat-dialog-content>
  <p>{{ 'consents.dialog_desc' | translate }}</p>

  <app-validation-message *ngIf="(error$ | async) as error" [type]="ValidationMessageType.Error" class="d-block mb-4">
    {{ error | translatedErrorMessage }}
  </app-validation-message>

  <app-consents [forms]="forms" [submitted]="submitted"></app-consents>

</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-between">
  <button class="btn" type="button" (click)="logout()">{{ 'buttons.logout' | translate }}</button>
  <button class="btn btn-primary" type="button" (click)="save()">{{ 'buttons.proceed' | translate }}</button>
</mat-dialog-actions>