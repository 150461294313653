// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const API_BASE_URL = 'https://api.meetingapplication.com/api/v1';
export const API_BASE_URL = 'https://api.meetingapp.pl/api/v1';
export const API_AUTH_BASE_URL = 'https://auth.meetingapp.pl/api/v1';
export const API_REGISTRATION_BASE_URL = 'https://registration.meetingapp.pl/api/v1';
export const API_PAYMENT_BASE_URL = 'https://payment.meetingapp.pl/api/v1';

export const API_BASE_URLS = [
  API_BASE_URL,
  API_AUTH_BASE_URL,
  API_REGISTRATION_BASE_URL,
  API_PAYMENT_BASE_URL,
];

// export const API_BASE_URL = 'http://localhost:3000/api/v1';
export const TOKEN_KEY = 'escl_token';
export const STRIPE_KEY = 'pk_test_gxVJhmi2gC5zSp6IkdltY5uN';
export const CONFIGURATOR_PAGE_BASE_URL = 'https://landing.meetingapp.pl';
export const LANDING_PAGE_BASE_URL = 'https://landing.meetingapp.pl';
export const WIDGET_PAGE_BASE_URL = 'https://lpdev.meetingapp.pl';
export const PUSHER_API_KEY = '8170ba4f2a23b6e23a24';

export const FACEBOOK_APP_ID = '278375644825263';

export const APPLE_CLIENT_ID = 'com.meetingapplication.lp';
export const APPLE_REDIRECT_URI = 'https://panelprod.meetingapp.pl';

export const EDGE_TICKET_ID = '148';
export const HUBSPOT_CUSTOM_BTN_EVENT_ID = '238';

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
