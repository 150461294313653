import { StoreModule } from "@ngrx/store";
import * as fromIndex from './index';
import { InjectionToken, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { ConsentEffects } from "./effects/consent.effects";
import { ConsentApiService } from "./apis/consent-api.service";
import { ConsentAdapterService } from "./adapters/consent-adapter.service";

export const CONSENT_REDUCERS = new InjectionToken('Consent Reducers');


@NgModule({
  imports: [
    StoreModule.forFeature(fromIndex.featureKey, CONSENT_REDUCERS),
    EffectsModule.forFeature([
      ConsentEffects,
    ]),
  ],
  providers: [
    ConsentApiService,
    ConsentAdapterService,
    {
      provide: CONSENT_REDUCERS,
      useValue: fromIndex.reducers,
    },
  ]
})
export class ConsentStoreModule {}