import { NoScrollInputDirective } from './directives/no-scroll-input.directive';
import { DebounceClick } from './directives/debounce-click.directive';
import { ShowIfBetweenWidthDirective } from './directives/show-if-between-width.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { TagDatabaseModalService } from '../components/tag-database/tag-database-modal.service';

import { ButtonAddComponent } from '@shared/components/button-add/button-add.component';
import { ButtonAddCenterComponent } from '@shared/components/button-add-center/button-add-center.component';
import { ButtonAddInlineComponent } from '@shared/components/button-add-inline/button-add-inline.component';
import { ButtonSettingsComponent } from '@shared/components/button-settings/button-settings.component';
import { ButtonActionsComponent } from '@shared/components/button-actions/button-actions.component';
import { SearchBarComponent } from '@shared/components/search-bar/search-bar.component';
import { PanelComponent } from '@shared/components/panel/panel.component';
import { PanelHeaderComponent } from '@shared/components/panel-header/panel-header.component';
import { PanelContentComponent } from '@shared/components/panel-content/panel-content.component';
import { PanelBodyComponent } from '@shared/components/panel-body/panel-body.component';
import { PanelFooterComponent } from '@shared/components/panel-footer/panel-footer.component';
import { SpinnerOverlayComponent } from '@shared/components/spinner-overlay/spinner-overlay.component';
import { SpinnerProgressComponent } from '@shared/components/spinner-progress/spinner-progress.component';
import { CategoryNameComponent } from './components/category-name/category-name.component';
import { CategoryLevelComponent } from './components/category-level/category-level.component';
import { CategoryComponent } from './components/category/category.component';
import { ModalErrorComponent } from '@shared/components/modal-error/modal-error.component';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';
import { WysiwygEditorComponent } from './components/wysiwyg-editor/wysiwyg-editor.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { AspectCanvasComponent } from '@shared/components/aspect-canvas/aspect-canvas.component';
import { MobileMapComponent } from '@shared/components/mobile-map/mobile-map.component';
import { MobilePreviewComponent } from './components/mobile-preview/mobile-preview.component';
import { GoogleMapComponent } from '@shared/components/google-map/google-map.component';
import { GoogleMapMarkerComponent } from '@shared/components/google-map-marker/google-map-marker.component';
import { CountryListComponent } from '@shared/components/country-list/country-list.component';
import { CountryInputComponent } from '@shared/components/country-input/country-input.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BadgeGroupComponent } from './components/badge-group/badge-group.component';
import { TagComponent } from '@shared/components/tag/tag.component';
import { TagGroupComponent } from '@shared/components/tag-group/tag-group.component';
import { TagDatabaseListComponent } from '@shared/components/tag-database-list/tag-database-list.component';
import { SortByComponent } from '@shared/components/sort-by/sort-by.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { IconComponent } from './components/icon/icon.component';
import { IconButtonComponent } from '@shared/components/icon-button/icon-button.component';
import { CopyInputComponent } from '@shared/components/copy-input/copy-input.component';
import { DateTimePickerComponent } from '@shared/components/date-time-picker/date-time-picker.component';
import { DatePickerComponent } from '@shared/components/date-picker/date-picker.component';
import { TimePickerComponent } from '@shared/components/time-picker/time-picker.component';
import { BatchActionGroupComponent } from '@shared/components/batch-action-group/batch-action-group.component';
import { BatchActionComponent } from './components/batch-action/batch-action.component';
import { LikeCounterComponent } from '@shared/components/like-counter/like-counter.component';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { ActionButtonGroupComponent } from './components/action-button-group/action-button-group.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { ActionMenuItemComponent } from './components/action-menu-item/action-menu-item.component';
import { InputAdaptiveComponent } from './components/input-adaptive/input-adaptive.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarStackComponent } from '@shared/components/avatar-stack/avatar-stack.component';
import { InputTextIconComponent } from '@shared/components/input-text-icon/input-text-icon.component';
import { InputPhoneNumberComponent } from '@shared/components/input-phone-number/input-phone-number.component';
import { ButtonNewCircleComponent } from '@shared/components/button-new-circle/button-new-circle.component';
import { RatingComponent } from '@shared/components/rating/rating.component';
import { VirtualScrollComponent } from '@shared/components/virtual-scroll/virtual-scroll.component';
import { PriceFieldComponent } from './components/price-field/price-field.component';
import { LanguageTabComponent } from './components/language-tab/language-tab.component';
import { LanguageTabListComponent } from './components/language-tab-list/language-tab-list.component';
import { LanguageFlagComponent } from './components/language-flag/language-flag.component';
import { LanguageSettingModalComponent } from './components/language-setting-modal/language-setting-modal.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorPalleteComponent } from './components/color-pallete/color-pallete.component';
import { ImportModalComponent } from './components/import-modal/import-modal.component';
import { ModalProgressComponent } from './components/modal-progress/modal-progress.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { UserSelectDropdownComponent } from './components/user-select-dropdown/user-select-dropdown.component';
import { SpinnerConditionalComponent } from './components/spinner-conditional/spinner-conditional.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';

import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { CheckboxGroupDirective } from '@shared/directives/checkbox-group.directive';
import { CheckboxControlDirective } from '@shared/directives/checkbox-control.directive';
import { GoogleMapAutocompleteDirective } from '@shared/directives/google-map-autocomplete.directive';
import { VirtualScrollItemDirective } from '@shared/directives/virtual-scroll-item.directive';
import { VirtualScrollPlaceholderDirective } from '@shared/directives/virtual-scroll-placeholder.directive';
import { FormValidateDirective } from './directives/form-validate.directive';
import { FormValidateFeedbackDirective } from './directives/form-validate-feedback.directive';
import { FormContainerDirective } from './directives/form-container.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { VirtualScrollDirective } from './directives/virtual-scroll.directive';
import { FileDragDirective } from './components/file-uploader/file-drag.directive';
import { FormFeedbackDirective } from './directives/form-feedback.directive';

import { QrcodePipe } from '@shared/pipes/qrcode.pipe';
import { DateRangePipe } from '@shared/pipes/date-range.pipe';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { ExtractErrorPipe } from './pipes/extract-error.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { SvgPreviewComponent } from './components/svg-preview/svg-preview.component';
import { DropdownWithSearchComponent } from './components/dropdown-with-search/dropdown-with-search.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { MatSelectModule } from '@angular/material/select';
import { DeeplinkBuilderModalComponent } from './components/deeplink-builder-modal/deeplink-builder-modal.component';
import { DeeplinkBuilderComponent } from './components/deeplink-builder/deeplink-builder.component';
import { TrimmerPipe } from './pipes/trimmer.pipe';
import { TooltipDirective } from './directives/tooltip.directive';
import { FileUploaderDialogComponent } from './components/file-uploader-dialog/file-uploader-dialog.component';
import { ExhibitorsSelectDropdownComponent } from './components/exhibitors-select-dropdown/exhibitors-select-dropdown.component';
import { CountryDropdownComponent } from './components/country-dropdown/country-dropdown.component';
import { AngularCountriesFlagsModule } from 'angular-countries-flags';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { NoSpaceLowerCaseDirective } from './directives/no-space-lower-case.directive';
import { ColorsPreviewComponent } from './components/colors-preview/colors-preview.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TipCardComponent } from './components/tip-card/tip-card.component';
import { SignInStrategyComponent } from './components/sign-in-strategy/sign-in-strategy.component';
import { SpinnerFullscreenComponent } from './components/spinner-fullscreen/spinner-fullscreen.component';
import { StatusBarComponent } from './components/status-bar/status-bar.component';
import { SmallTipComponent } from './components/small-tip/small-tip.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownItemComponent } from './components/dropdown-item/dropdown-item.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { ApplicationLanguageSelectorComponent } from './components/application-language-selector/application-language-selector.component';
import { DropdownItemCheckboxComponent } from './components/dropdown-item-checkbox/dropdown-item-checkbox.component';
import { SoryByOrderPipe } from './pipes/sort-by-order.pipe';
import { ShowForRoleDirective } from './directives/show-for-role.directive';
import { DisabledForRoleDirective } from './directives/disabled-for-role.directive';
import { ComponentOnboardingDialogComponent } from './components/component-onboarding-dialog/component-onboarding-dialog.component';
import { AccessGroupControlComponent } from './components/access-group-control/access-group-control.component';
import { TagControlComponent } from './components/tag-control/tag-control.component';
import { TagsControlComponent } from './components/tags-control/tags-control.component';
import { TranslatedErrorMessagePipe } from './pipes/translated-error-message.pipe';
import { TranslateProductPipe } from './pipes/product-translated.pipe';
import { ChipsInputComponent } from './components/chips-input/chips-input.component';
import { TabSwitchControlComponent } from './components/tab-switch-control/tab-switch-control.component';
import { SelectableTagComponent } from './components/selectable-tag/selectable-tag.component';
import { AddButtonComponent } from './components/add-button/add-button.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { InputValueGeneratorComponent } from './components/input-value-generator/input-value-generator.component';
import { MatMenuModule } from '@angular/material/menu';
import { ConsentsDialogComponent } from './components/consents-dialog/consents-dialog.component';
import { ConsentCheckboxComponent } from './components/consent-checkbox/consent-checkbox.component';
import { ConsentsComponent } from './components/consents/consents.component';

export const COMPONENTS = [
  ButtonAddComponent,
  ButtonAddCenterComponent,
  ButtonAddInlineComponent,
  ButtonSettingsComponent,
  ButtonActionsComponent,
  SearchBarComponent,
  PanelComponent,
  PanelHeaderComponent,
  PanelContentComponent,
  PanelBodyComponent,
  PanelFooterComponent,
  SpinnerOverlayComponent,
  SpinnerProgressComponent,
  CategoryNameComponent,
  CategoryLevelComponent,
  CategoryComponent,
  ModalErrorComponent,
  ModalMessageComponent,
  WysiwygEditorComponent,
  CodeEditorComponent,
  AspectCanvasComponent,
  MobileMapComponent,
  MobilePreviewComponent,
  GoogleMapComponent,
  GoogleMapMarkerComponent,
  CountryListComponent,
  CountryInputComponent,
  BadgeComponent,
  BadgeGroupComponent,
  TagComponent,
  TagGroupComponent,
  TagDatabaseListComponent,
  SortByComponent,
  PlaceholderComponent,
  IconComponent,
  IconButtonComponent,
  CopyInputComponent,
  DateTimePickerComponent,
  DatePickerComponent,
  TimePickerComponent,
  BatchActionGroupComponent,
  BatchActionComponent,
  LikeCounterComponent,
  ActionButtonComponent,
  ActionButtonGroupComponent,
  ActionMenuComponent,
  ActionMenuItemComponent,
  InputAdaptiveComponent,
  AvatarComponent,
  AvatarStackComponent,
  InputTextIconComponent,
  InputPhoneNumberComponent,
  ButtonNewCircleComponent,
  RatingComponent,
  VirtualScrollComponent,
  PriceFieldComponent,
  LanguageTabComponent,
  LanguageTabListComponent,
  LanguageFlagComponent,
  LanguageSettingModalComponent,
  LanguageSwitchComponent,
  ColorPickerComponent,
  ColorPalleteComponent,
  ImportModalComponent,
  ModalProgressComponent,
  UserSelectComponent,
  UserSelectDropdownComponent,
  SpinnerConditionalComponent,
  FileUploaderComponent,
  HelpButtonComponent,
  SvgPreviewComponent,
  DropdownWithSearchComponent,
  PaginatorComponent,
  ToastMessageComponent,
  ConfirmModalComponent,
  DeeplinkBuilderComponent,
  DeeplinkBuilderModalComponent,
  FileUploaderDialogComponent,
  ExhibitorsSelectDropdownComponent,
  CountryDropdownComponent,
  DoughnutChartComponent,
  LineChartComponent,
  SignInStrategyComponent,
  ColorsPreviewComponent,
  ImageCropperComponent,
  TipCardComponent,
  SpinnerFullscreenComponent,
  StatusBarComponent,
  SmallTipComponent,
  DropdownComponent,
  DropdownItemComponent,
  DropdownItemCheckboxComponent,
  ValidationMessageComponent,
  ApplicationLanguageSelectorComponent,
  ComponentOnboardingDialogComponent,
  AccessGroupControlComponent,
  TagControlComponent,
  TagsControlComponent,
  ChipsInputComponent,
  TabSwitchControlComponent,
  SelectableTagComponent,
  AddButtonComponent,
  ExpansionPanelComponent,
  InputValueGeneratorComponent,
  ConsentsDialogComponent,
  ConsentCheckboxComponent,
  ConsentsComponent,
];

export const DIRECTIVES = [
  AutofocusDirective,
  CheckboxGroupDirective,
  CheckboxControlDirective,
  GoogleMapAutocompleteDirective,
  VirtualScrollItemDirective,
  VirtualScrollPlaceholderDirective,
  FormValidateDirective,
  FormValidateFeedbackDirective,
  FormContainerDirective,
  FormSubmitDirective,
  VirtualScrollDirective,
  FileDragDirective,
  FormFeedbackDirective,
  OnlyNumbersDirective,
  ShowIfBetweenWidthDirective,
  NoScrollInputDirective,
  DebounceClick,
  TooltipDirective,
  ClickOutsideDirective,
  NoSpaceLowerCaseDirective,
  ShowForRoleDirective,
  DisabledForRoleDirective,
];

export const PIPES = [
  QrcodePipe,
  DateRangePipe,
  FileSizePipe,
  ExtractErrorPipe,
  DateTimeFormatPipe,
  TrimmerPipe,
  LocalizedDatePipe,
  SoryByOrderPipe,
  TranslatedErrorMessagePipe,
  TranslateProductPipe,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        ScrollingModule,
        MatDialogModule,
        MatTableModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        TranslateModule,
        MatSelectModule,
        AngularCountriesFlagsModule,
        NgChartsModule,
        ImageCropperModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatMenuModule,
    ],
    declarations: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES,
    ],
    exports: [
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES,
        TranslateModule,
    ],
    providers: [
        TagDatabaseModalService,
        MatDatepickerModule,
    ]
})
export class SharedModule {}
