<div class="tag-select">
  <app-dropdown-with-search [options]="userGroupOptions$ | async" [label]="placeholder" [staticLabel]="true" (onSelect)="tagSelected($event)" [disabled]="disabled">
  </app-dropdown-with-search>

  <button class="btn btn-outline-secondary tag-select-btn" type="button" (click)="onTagsEdit()">
    <app-icon icon="/assets/icons/tag.svg"></app-icon>
  </button>
</div>

<app-tag-group *ngIf="selectedTags.length > 0; else noSelectedTagsTemplate">
  <app-tag *ngFor="let tag of selectedTags" [tag]="tag" [readOnly]="true" [hideRemove]="hideRemove || disabled" (appRemove)="removeTag(tag)"></app-tag>
</app-tag-group>

<ng-template #noSelectedTagsTemplate>
  <p>{{ 'others.no_tags_selected' | translate }}</p>
</ng-template>