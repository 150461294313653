import { Pipe, PipeTransform } from '@angular/core';

import { extractErrorFrom } from '@utils/errors.util';

@Pipe({
  name: 'extractError'
})
export class ExtractErrorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return extractErrorFrom(value);
  }
}