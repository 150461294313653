import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ConsentAdapterService } from "../adapters/consent-adapter.service";
import { acceptUserConsents, acceptUserConsentsFailure, acceptUserConsentsSuccess, loadUserAppConsents, loadUserAppConsentsFailure, loadUserAppConsentsSuccess } from "../actions/consent.actions";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { TokenService } from "@app/core/services/token.service";

@Injectable()
export class ConsentEffects {

  loadUserAppConsents$ = createEffect(() => this.actions$.pipe(
    ofType(loadUserAppConsents),
    switchMap(_ => this.adapter.getUserAppConsents().pipe(
      map(entities => loadUserAppConsentsSuccess({ entities })),
      catchError(error => {
        console.log(error);
        return of(loadUserAppConsentsFailure({ error }));
      })
    ))
  ));

  acceptUserConsents$ = createEffect(() => this.actions$.pipe(
    ofType(acceptUserConsents),
    switchMap(action => this.adapter.acceptConsents(action.payload).pipe(
      tap(() => this.tokenService.refreshWithCurrentToken()),
      map(acceptedIds => acceptUserConsentsSuccess({ acceptedIds })),
      catchError(error => of(acceptUserConsentsFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private adapter: ConsentAdapterService,
    private tokenService: TokenService,
  ) {}
}