import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ConsentDisplayPlaceholderModel } from "../models/consent-display.model";
import { ConsentModel } from "../models/consent.model";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ModelForm } from "@utils/model-form.util";

export interface ConsentCheckboxFormModel {
  attrId: string;
  text: SafeHtml;
  checked: boolean;
  data: ConsentModel;
  expanded: boolean;
  expandableBtnText: string | null;
  expandableText: SafeHtml | null;
}

function createCheckboxForm(sanitizer: DomSanitizer, id: number, consent: ConsentModel): FormGroup<ModelForm<ConsentCheckboxFormModel>> {
  const text = makeHtmlText(sanitizer, consent.displayConfig.text, consent.displayConfig.placeholders);
  const expandableText = consent.displayConfig.expandable ? makeHtmlText(sanitizer, consent.displayConfig.expandable.text, consent.displayConfig.expandable.placeholders) : null;
  const expandableBtnText = consent.displayConfig.expandable?.button || null;
  const checkedValidator = consent.required ? trueValidator() : [];
  const attrId = `checkbox-${id}`;
  return new FormGroup<ModelForm<ConsentCheckboxFormModel>>({
    attrId: new FormControl(attrId),
    text: new FormControl(text),
    checked: new FormControl(false, checkedValidator),
    data: new FormControl(consent),
    expanded: new FormControl(false),
    expandableBtnText: new FormControl(expandableBtnText),
    expandableText: new FormControl(expandableText),
  });
}

function makeHtmlText(sanitizer: DomSanitizer, mainText: string, placeholders: ConsentDisplayPlaceholderModel[]): SafeHtml {
  let text = mainText;
  const links = placeholders.map(
    ph => `<a href="${ph.value}" class="ph-link" target="_blank" rel="noopener noreferrer">${ph.label}</a>`
  );
  links.forEach((link, index) => {
    const placeholder = `\\{${index}\\}`;
    text = text.replace(new RegExp(placeholder, 'g'), link);
  });
  return sanitizer.bypassSecurityTrustHtml(text);
}

function trueValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value === true ? null : { notTrue: true };
  };
}

export const ConsentHelper = {
  makeHtmlText,
  createCheckboxForm,
}