import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_AUTH_BASE_URL } from "@env/environment";
import { Observable } from "rxjs";
import { ConsentApiModel } from "../models/api/consent.api-model";
import { ConsentUserAcceptApiModel } from "../models/api/consent-user-accept.api-model";

@Injectable()
export class ConsentApiService {
  constructor(private http: HttpClient) {}

  getUserAppConsents(): Observable<ConsentApiModel[]> {
    return this.http.get<ConsentApiModel[]>(`${getBaseUrl()}/user_app_consents`);
  }

  acceptConsents(payload: ConsentUserAcceptApiModel[]): Observable<void> {
    return this.http.post<void>(`${API_AUTH_BASE_URL}/user_consents`, { consents: payload });
  }
}

const getBaseUrl = () => `${API_AUTH_BASE_URL}/consent_schemas`;