import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

const API_KEY = 'AIzaSyAqHNDWkejRgyevo-VkPAEyqMOFRwAjcGI';

@Injectable()
export class GoogleApiService {
  private googleMapsApi$: BehaviorSubject<any>;

  private loadPromise: Promise<any>;
  private libraries: string;

  constructor() {
    this.googleMapsApi$ = new BehaviorSubject<any>(void 0);

    this.loadPromise = null;
    this.libraries = 'places';

    from(this.loadGoogleMapsApi()).subscribe({
      next: mapsApi => this.googleMapsApi$.next(mapsApi),
      error: err => console.error('Failed to load Google Maps API', err)
    });
  }

  getMapsApi(): Observable<any> {
    return this.googleMapsApi$.asObservable().pipe(
      filter(mapsApi => !!mapsApi), take(1)
    );
  }

  getGeocoder(): any {
    if (window['google'] && window['google'].maps && window['google'].maps.Geocoder) {
      return new window['google'].maps.Geocoder();
    } else {
      throw new Error('Google Maps API not loaded yet.');
    }
  }

  getStaticMapImageUrl(options: any): Observable<string> {
    // HttpParams object is immutable
    let params = new HttpParams({
      fromObject: { key: API_KEY }
    });

    for (const key of Object.keys(options)) {
      if (Array.isArray(options[key])) {
        options[key].forEach(value => (params = params.append(key, value)));
      } else {
        params = params.append(key, options[key]);
      }
    }

    return of(`https://maps.googleapis.com/maps/api/staticmap?${params.toString()}`);
  }

  private loadGoogleMapsApi(): Promise<any> {
    if (this.loadPromise) {
      console.warn('Google Maps already loaded, reuse promise');
      return this.loadPromise;
    }

    const script = createScriptTag({
      src: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMapApi`,
      id: 'google-maps-api-script'
    });

    if (typeof this.libraries === 'string') {
      script.src += `&libraries=${this.libraries}`;
    }

    this.loadPromise = new Promise<any>((resolve, reject) => {
      window['initMapApi'] = () => resolve(window['google'].maps);
      script.onerror = () => reject();
    });

    // start google maps api loading
    document.body.appendChild(script);

    return this.loadPromise;
  }
}

function createScriptTag(options: { src: string, id: string }): HTMLScriptElement {
  const script = document.createElement('script');

  Object.assign(script, options);
  script.type = 'text/javascript';
  script.defer = true;
  script.async = true;

  return script;
}
