import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ModalErrorComponent } from '@shared/components/modal-error/modal-error.component';
import { TranslateService } from '@ngx-translate/core';
import { ConsentsDialogComponent } from '@shared/components/consents-dialog/consents-dialog.component';
import { ConsentProviderService } from '@store/features/consents/providers/consent-provider.service';

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

  EXCLUDED_URL_PARTS = [
    'register',
    'login',
    'send_magic_link',
    'sign_in_magic_link',
    'sign_in_facebook',
    'sign_in_apple',
    'sign_up',
    'resend_confirm',
    'generate_new_password',
    'change_password',
  ];

  EXCLUDED_ERROR_CODES = [
    'sign_in.no_gdpr',
    'user.email_need_to_be_confirmed',
    'event.to_delete.published_event_error',
  ];

  constructor(
    private dialog: MatDialog,
    private i18n: TranslateService,
    private consentProvider: ConsentProviderService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && !this.isErrorExcluded(error)) {
          const code = error?.error?.errors[0]?.code || null;
          if (code && code === 'not_authorized_missing_system_consents') {
            this.consentProvider.loadUserAppConsents();
            this.dialog.open(ConsentsDialogComponent, {
              width: '400px',
              maxHeight: '90vh',
              disableClose: true,
            });
          } else {
            this.dialog.open(ModalErrorComponent, {
              width: '400px',
              height: '300px',
              data: {
                title: this.i18n.instant('others.forbidden_dialog_title'),
                message: this.i18n.instant('others.forbidden_dialog_desc'),
                buttonI18nKey: 'buttons.submit',
              }
            });
          }
        }
        return throwError(error);
      })
    );
  }

  private isErrorExcluded(exception: HttpErrorResponse): boolean {
    if (exception.error ** exception.error.errors) {
      if(this.EXCLUDED_ERROR_CODES.includes(exception.error.errors[0])) {
        return true;
      }
    }
    const isExcludedUrl = this.EXCLUDED_URL_PARTS.some(url => exception.url.includes(url));
    return isExcludedUrl;
  }
}