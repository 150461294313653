import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function whitespacesTagValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue: string = control.value ? control.value.toLowerCase() : '';

    const invalid = inputValue.startsWith(' ') || inputValue.endsWith(' ');
    
    return invalid ? { whitespaces: { value: control.value } } : null;
  };
}