import { Injectable } from '@angular/core';
import { MatDialog, MatDialogClose } from '@angular/material/dialog';
import { ApplicationTagsEditModalComponent } from '@components/application-tags/application-tags-edit-modal/application-tags-edit-modal.component';
import { ComponentModel } from '@shared/models/component.model';
import { TagApiModel } from '@store/features/tags/tags.models';
import { Observable } from 'rxjs';

export enum ApplicationTagsModalEditFor {
  Component = 'component',
  ChildItem = 'item'
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationTagsModalService {
  constructor(
    private dialog: MatDialog
  ) {}

  openEditDialog(component: ComponentModel, item?: any): Observable<TagApiModel[]> {
    return this.openEditModalFor(component, item)
  }

  protected openEditModalFor(component: ComponentModel, item?: any): Observable<TagApiModel[]> {
    const dialog = this.dialog.open(ApplicationTagsEditModalComponent, {
      width: '50%', height: '100%', position: { right: '0' }, disableClose: true, panelClass: ['animated-modal']
    });

    dialog.componentInstance.component = component;

    if(item) {
      dialog.componentInstance.childItem = item;
      dialog.componentInstance.editFor = ApplicationTagsModalEditFor.ChildItem
    } else {
      dialog.componentInstance.editFor = ApplicationTagsModalEditFor.Component
    }

    return dialog.afterClosed();
  }
}
