<div class="consent-checkbox">
  <mat-checkbox [id]="form.value.attrId" [checked]="form.controls.checked.value" (change)="handleStateChanged($event.checked)">
  </mat-checkbox>
  <div class="label">
    <span [attr.for]="form.value.attrId" [innerHTML]="form.value.text">
    </span>
    <a class="btn-link" (click)="changeFormExpanded($event)"><br />{{ form.value.expandableBtnText }}</a>
    <div class="expanded-content mt-2" *ngIf="form.controls.expanded.value" [innerHTML]="form.value.expandableText"></div>
    <div *ngIf="form.invalid && submitted" class="form-control-feedback mt-1">
      {{ 'consents.consent_req' | translate }}
    </div>
  </div>