import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface SelectableTagModel {
  id: number;
  value: string;
}

@Component({
  selector: 'app-selectable-tag',
  templateUrl: './selectable-tag.component.html',
  styleUrls: ['./selectable-tag.component.scss']
})
export class SelectableTagComponent {

  @Input() tag: SelectableTagModel;
  @Input() active: boolean;
  @Input() constSelection = false;
  @Input() deleteDisabled = false;

  @Output() onSelect: EventEmitter<void>;
  @Output() onDelete: EventEmitter<void>;

  constructor() {
    this.onDelete = new EventEmitter<void>();
    this.onSelect = new EventEmitter<void>();
  }
  
  handleDelete(): void {
    this.onDelete.emit();
  }

  handleSelect(): void {
    this.onSelect.emit();
  }

}
