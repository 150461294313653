import { EventFacadeService } from '@store/features/event/event-facade.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventModel } from '@store/features/event/models/event.model';
import { Observable } from 'rxjs';
import { API_BASE_URL, API_BASE_URLS } from '@env/environment';
import { map, switchMap, take } from 'rxjs/operators';
import { LangService } from '@shared/providers/lang.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderLangInterceptor implements HttpInterceptor {
  event$: Observable<EventModel>;
  
  translatableRequests: string[] = [
    '/component_definitions'
  ];

  constructor(
    private eventFacade: EventFacadeService,
    private langService: LangService,
  ) {
    this.event$ = this.eventFacade.getActiveEvent().pipe(take(1));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isApiRequest(req) || isWhitelisted(req)) {
      return next.handle(req);
    }

    if (this.translatableRequests.some(fragment => req.url.includes(fragment))) {
      const lang = this.langService.getCurrentUserLang();
      return next.handle(decorateRequest(req, lang));
    }

    return this.handleRequest(req, next);
  }

  private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const acceptLangHeader = req.headers.get('Accept-Language');
    const contentLangHeader = req.headers.get('CONTENT_LANGUAGE');
    if(!acceptLangHeader && !contentLangHeader) {
      return this.event$.pipe(
        map(event => decorateRequest(req, event?.language)),
        switchMap(eventReq => next.handle(eventReq)),
      );
    } else if(contentLangHeader) {
      return next.handle(decorateRequest(req, contentLangHeader));
    }
    return next.handle(req);
  }
}
  
function decorateRequest(req: HttpRequest<any>, language?: string): HttpRequest<any> {
  const headers = req.headers.set('Accept-Language', (language ? language : 'en'));
  return req.clone({ headers });
}

function isApiRequest(req: HttpRequest<any>): boolean {
  return API_BASE_URLS.some(url => req.url.startsWith(url));
}

function isWhitelisted(req: HttpRequest<any>): boolean {
  const whitelist = ['/login', '/register', '/refresh_token', '/recovery_account', '/resend_confirm', '/recovery_account', '/send_magic_link', '/sign_in_magic_link'];
  return whitelist.some(fragment => req.url.includes(fragment));
}