import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromConsent from './reducers/consent.reducer';

export const featureKey = 'consentsFeature';

export interface State {
  [fromConsent.childKey]: fromConsent.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromConsent.childKey]: fromConsent.reducer,
}

export const selectFeatureKey = createFeatureSelector<State>(featureKey);

export const selectConsentFeature = createSelector(
  selectFeatureKey,
  state => state[fromConsent.childKey]
);

// CONSENT - SELECTORS START

export const selectConsentLoading = createSelector(
  selectConsentFeature,
  fromConsent.selectLoading
);

export const selectConsentLoaded = createSelector(
  selectConsentFeature,
  fromConsent.selectLoaded
);

export const selectConsentAccepting = createSelector(
  selectConsentFeature,
  fromConsent.selectAccepting
);

export const selectConsentError = createSelector(
  selectConsentFeature,
  fromConsent.selectError
);

export const selectConsentEntities = createSelector(
  selectConsentFeature,
  fromConsent.selectEntities
);

// CONSENT - SELECTORS END