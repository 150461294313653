import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConsentCheckboxFormModel } from '@store/features/consents/utils/consent.helper';
import { ModelForm } from '@utils/model-form.util';

@Component({
  selector: 'app-consent-checkbox',
  templateUrl: './consent-checkbox.component.html',
  styleUrls: ['./consent-checkbox.component.scss']
})
export class ConsentCheckboxComponent {

  @Input() form: FormGroup<ModelForm<ConsentCheckboxFormModel>>;
  @Input() submitted: boolean;

  handleStateChanged(state: boolean): void {
    this.form.controls.checked.setValue(state);
  }

  changeFormExpanded(event: MouseEvent): void {
    event.preventDefault();
    this.form.controls.expanded.setValue(!this.form.controls.expanded.value);
  }

}
